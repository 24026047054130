const config = {
  rootProjectId: "root",
  uiBucketName: "gratisdev.c.retter.io",
  appUrl: "https://api.gratisdev.retter.io/",
  cosUrl: "api.gratisdev.retter.io",
  version: "2.1.22",
  captchaKey: "6LefK0spAAAAAA4Xxa1fp3Q2edTaekm-RkUgSWga",
  stage: "PROD"
}
export default config

